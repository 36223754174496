<template lang="pug">
.Content
  Header
  .headerMenu(v-if="isPopup || isCorrect")
    button(@click="onGoBackSim" type="button").button-without-styles.py-3.px-4.isPopu
      svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
        path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#0058A2' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
  .headerFixed(v-if="isntIntersection && !isCorrect && !isPopup")
    button(v-if="!isCorrect" @click="goToView('Autenticate')" type="button").button-without-styles.py-3.px-4.isBad
      svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
        path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#ffff' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
    button(v-else @click="handleContinue" type="button").button-without-styles.py-3.px-4.isSoft
      svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
        path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#ffff' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
    h2(v-if="isCorrect").TitleSection.text-start.color-white(style="margin-top: 50px;") ¿Dónde te atendimos?
  .text-center(v-if="isPopup")
    .d-flex(style="align-items: center; justify-content: center; height: calc(100vh - 112.531px); flex-direction: column")
      h2.TitleSection.pl-0.pt-5.pb-5#headerCalendarTitle2 ¡Información!
      p.TextSection.mt-2.mb-0.mx-3.color-dark Este canal es sólo para solicitudes de servicios médicos de medicina prepagada.
      p.TextSection.mt-2.mb-0.mx-3.color-dark 
        | Si requieres  servicios de  Aliansalud EPS, como
        strong.ml-1 Medicamentos ambulatorios
        | , conoce cómo en el botón 'Más información'
    .botton-actions-footer
      .d-flex.justify-content-between.mb-3
        b-button(variant="bluecolmedica" size="sm" type="button" @click="handleUnderstand").w-100.py-2.text-center.mx-1
          span.font-md Entendido
      a(href="https://www.aliansalud.com.co/Paginas/Canales-de-atencion.aspx" target="_blank" style="color: #226DB1; text-decoration: underline; font-size: 18px;").TextSection.pt-4.mb-0.mx-3.color-dark
        strong Más información
  .contain(v-if="!isCorrect && !isPopup")
    .text-center.d-block
      svg(width='32' height='32' viewbox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg')
        path(d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16345 24.8366 -6.26249e-07 16 -1.39876e-06C7.16345 -2.17128e-06 2.17128e-06 7.16344 1.39876e-06 16C0.00937525 24.8326 7.16733 31.9906 16 32Z' fill='#3290E0')
        path(d='M15.9987 24.9805C17.1446 24.9805 18.0117 24.3824 18.0117 23.4165L18.0117 14.5565C18.0117 13.5895 17.1448 12.9935 15.9987 12.9935C14.8807 12.9935 13.9856 13.6144 13.9856 14.5565L13.9856 23.4165C13.9857 24.3595 14.8807 24.9805 15.9987 24.9805ZM15.9987 10.9974C17.0997 10.9974 17.9957 10.1014 17.9957 8.99949C17.9957 7.8995 17.0997 7.00344 15.9987 7.00344C14.8977 7.00344 14.0026 7.8995 14.0026 8.99949C14.0027 10.1015 14.8977 10.9974 15.9987 10.9974Z' fill='#F4F4F4')
    p.TextSection.mt-2.mb-0.mx-3.color-dark Confirma la información del usuario que requiere la autorización
    .form-data.mb-3.mt-4
      .p-4
        .d-flex.align-items-start.boxOfText
          p.font-16.mb-0 Documento:
          p.mb-0.ml-1: strong {{ selectedOption }}
        .d-flex.align-items-start.boxOfText.flex-wrap
          p.font-16.mb-0.text-break-white.mr-1 Número de documento:
          p.mb-0: strong {{ docNumber }}
        .d-flex.align-items-start.boxOfText
          p.font-16.mb-0 Nombre:
          p.mb-0.ml-1: strong {{ name }}
        .d-flex.align-items-start.boxOfText
          p.font-16.mb-0.text-break-white Celular:
          p.mb-0.ml-1: strong {{ phone }}
        .d-flex.align-items-start.boxOfText.flex-wrap.justify-content-center
          .w-100
            p.font-16.mb-0.text-break-white.mr-1 Correo electrónico:
          p.mb-0.d-flex.flex-wrap.ml-0.justify-content-center
            strong.text-truncate(style="max-width: 80%;") {{ email1 }}
            strong.text-truncate(style="max-width: 80%;") {{ email2 }}
      .text-center.pb-4.px-4(style="position: relative; border-radius: 0 0 21.4881px 21.4881px;")
        p: strong Enviaremos la respuesta de la autorización al correo registrado.
        span.ml-1 Si requieres actualizar datos, puedes hacerlo en la página web, línea de asistencia o en la videollamada con el asesor que realice la atención
    .botton-actions-footer
      .d-flex.justify-content-between
        b-button(variant="bluecolmedica" size="sm" type="button" @click="handleContinue").w-100.py-2.text-center.mx-1
          span.font-md Continuar con estos datos
  
  //- select service
  .contain.color-colmedica-gray(v-else-if="!servicioSelected && isCorrect && !isPopup")
      h2.TitleSection.text-center.m-0 Selecciona el servicio que
      h2.TitleSection.text-center.mb-4 requieres autorizar:
      div
        button(
          @click="selecteService(service)"
          v-for="service in services"
          :key="service.code_name"
        ).w-100.button-without-styles.my-2.mt-3
          stack-item.btn-block.align-self-center.mx-auto.pl-2 {{ service.label }}
  .contain(v-else-if="servicioSelected && isCorrect && !isPopup")
    h2.TitleSection.text-start.pl-4.pt-1.ml-2#headerCalendarTitle2 ¿Dónde te atendimos?
    p.TextSection.mt-3.text-start.specialText.mb-0.mr-3 La orden médica a autorizar te la entregó un médico de:
    .mt-2
      button(
        @click="loadData('Centro Médico')"
      ).w-100.button-without-styles.my-2.mt-3.position-relative
        //- img.star(src="@/assets/star_new.svg")
        stack-item.btn-block.align-self-center.mx-auto Centro Médico Colmédica
      button(
        @click="loadData('Profesional de la red', 'files')"
      ).w-100.button-without-styles.my-2.mt-3.position-relative
        //- img.star(src="@/assets/one_new.svg")
        stack-item.btn-block.align-self-center.mx-auto Profesional de la red
      button(
        @click="loadData('Clínica', 'files')"
      ).w-100.button-without-styles.my-2.mt-3.position-relative
        //- img.star(src="@/assets/one_new.svg")
        stack-item.btn-block.align-self-center.mx-auto Clínica
    //- figure.details.mt-5
    //-   img(src="@/assets/star_new.svg")
    //-   p.TextSection.text-start.specialText.m-0 No se adjuntan Documentos
    //- figure.details
    //-   img(src="@/assets/one_new.svg")
    //-   p.TextSection.text-start.specialText.m-0 Se debe adjuntar Documentos
    .botton-actions-2.ml-4.pl-2.pt-1
      .d-flex.justify-content-between
        button(@click="setIsCorrect(!isCorrect)" type="button").button-without-styles
          svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#0058A2' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
  .botton-actions.ml-4.pl-2(v-if="!isCorrect")
    .d-flex.justify-content-between
      button(@click="goToView('Autenticate')" type="button").button-without-styles
        svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#0058A2' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
</template>
<script>
import { mapActions, mapState } from "vuex";
import Header from "./components/Header";
import Back from "mdi-vue/ArrowLeftBold.vue";
import Continue from "mdi-vue/ArrowRightBold";
// import { utf8_to_b64 } from "@/utils/decoder";
import asterisker from "@/helpers/asteriskHidden";
// import { getOneUseToken } from "@/helpers/Meet";
import _get from "lodash/get";
import StackItem from "@/views/components/StackItem.vue";

export default {
  name: "VerificationData",
  components: {
    Header,
    Back,
    Continue,
    StackItem
  },
  data: () => ({
    isPopup: false,
    isntIntersection: false
  }),
  mounted() {
    console.log("b64final");
    console.log(this.b64final);
    if (this.b64final.fu.includes("web")) {
      // show only select services
      // then. is correct info user by default
      this.setIsCorrect(true);

      // check 'Control de medicamentos'
      // this.isPopup = this.b64final.md;
    }

    // tracking
    setTimeout(() => {
      let auth = !this.client
        ? " autentitacion-no-cliente"
        : "autenticacion-cliente";
      let trackingData = {
        uuid: window.person.id,
        accion: "customer-" + auth,
        debmedia_turn_code: "",
        url_origen: window.location.href,
        origen: _get(this.client, "fu") || "ad",
        mensajes: ""
      };

      this.sendTracking(trackingData);
      this.observer = new IntersectionObserver(this.handlerObserver, {
        threshold: 1.0
      });

      this.observer.observe(document.querySelector("#headerCalendarTitle"));

      console.log("this.isPopup");
      console.log(this.isPopup);
    }, 1000);
  },
  computed: {
    ...mapState({
      options: state => state.autoservice.tipide,
      client: state => state.autoservice.client,
      b64final: state => state.autoservice.b64final,
      service: state => state.autoservice.service,
      servicioSelected: state => state.autoservice.servicioSelected,
      HorarioOutContinue: state => state.autoservice.HorarioOutContinue,
      currentUser: state => state.virtualrow.currentUser,
      isCorrect: state => state.autoservice.isCorrect,
      env: state => state.env,
      services: state => state.autoservice.servicesAvailable
    }),

    selectedOption() {
      return this.options.length
        ? this.options.find(option => option.value === this.b64final.ti)?.text
        : "";
    },

    docNumber() {
      return asterisker(this.b64final.nu, 0, 4);
    },

    name() {
      let pureName = (_get(this.client, "Nombres") || "").split(" ");
      let pureLastName1 = _get(this.client, "PrimerApellido") || "";
      let pureLastName2 = _get(this.client, "SegundoApellido") || "";
      return [...pureName, pureLastName1, pureLastName2]
        .filter(data => !!data)
        .map(word => word) // Before: asterisker(word, 2, 2)
        .join(" ");
    },

    phone() {
      let pure = _get(this.client, "Celular") || "";
      return asterisker(String(pure), 0, 4);
    },

    email1() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      return `${asterisker(splited[0], 2, 2)}`;
    },

    email2() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      if (splited.length > 0)
        return `@${asterisker(splited[1], 0, splited[1]?.length - 2)}`;
      else return "";
    }
  },
  methods: {
    ...mapActions({
      goToView: "autoservice/goToView",
      setService: "autoservice/setService",
      selecteService: "autoservice/selecteService",
      updateB64Final: "autoservice/updateB64Final",
      setIsCorrect: "autoservice/setIsCorrect",
      sendTracking: "virtualrow/handleTracking"
    }),

    onGoBackSim() {
      if (this.service) {
        // force select service again
        this.selecteService(null)
        return this.setService(null);
      }
      this.setIsCorrect(false);
      this.isPopup = false;
      this.goToView("Popup");
    },

    handleUnderstand() {
      this.isPopup = false;
      this.setIsCorrect(true);
    },

    handleContinue() {
      this.isntIntersection = false;
      const timer = setTimeout(() => {
        this.observer = new IntersectionObserver(this.handlerObserver, {
          threshold: 1.0
        });

        if (document.querySelector("#headerCalendarTitle2"))
          this.observer.observe(
            document.querySelector("#headerCalendarTitle2")
          );
        clearTimeout(timer);
      }, 1000);

      // Check ControlMedicamentos for popup
      if (this.client?.ControlMedicamentos) this.goToView("Popup");

      this.setIsCorrect(!this.isCorrect);
    },

    handlerObserver(entries) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          this.isntIntersection = true;
        } else {
          this.isntIntersection = false;
        }
      });
    },

    async toTurn() {
      this.updateB64Final({
        na: this.name,
        ma:
          this.currentUser?.PrioridadMarcaValor ||
          this.client?.PrioridadMarcaValor
      });

      // Check default tramite and sucursal demo
      if (this.env.VUE_APP_DEMO_MODE == "true") {
        this.updateB64Final({
          su: this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH,
          tr: this.env.VUE_APP_DEBMEDIA_DEFAULT_TRAMITE
        });
      }

      if (this.HorarioOutContinue) this.goToView("UploadFiles");
      // else {
      //   let to = await getOneUseToken();
      //   let jsonB64 = JSON.stringify({
      //     ...this.b64final,
      //     sv: this.service,
      //     to
      //   });
      //   this.$router.push({
      //     name: "Decoder",
      //     query: { pr: utf8_to_b64(jsonB64) }
      //   });
      // }
    },

    loadData(type, action) {
      //set point
      this.setService(type);
      if (!action) {
        this.toTurn();
        return this.goToView("IndexSchedules");
      }
      this.goToView("UploadFiles");
    }
  }
};
</script>
<style scoped>
.contain {
  margin-top: 50px;
  /* position: relative; */
  width: 100%;
  /* height: calc(100% - (58px + 22px + 50px)); */
}
.containNew {
  margin-top: 0;
}

/* Zone Actions Verify */
.text-middle {
  margin-top: 25px;
  font-size: 15px;
  text-align: center;
}

.form-data {
  margin-top: 40px;
  background-color: #f9f9f9;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  border-radius: 21.4881px;
}

.actions-zone {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 100px;
}

.min-button {
  min-width: 100px;
}

/* Zone Action Request */
.actions-zone-2 {
  margin-top: 30px;
}

/* botom actions button - autoservice */
.botton-actions {
  position: absolute;
  top: 0;
  /* width: 100%; */
  margin-top: 24px;
  left: 0;
}

.botton-actions-2 {
  position: absolute;
  top: 1.25rem;
  /* width: 100%; */
  left: 0;
}

.botton-actions-footer {
  position: fixed;
  bottom: 0;
  padding-bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 317.7px;
  width: 100%;
  z-index: 200;
}

.button-origen {
  font-size: 1.3rem;
}

.specialText {
  color: var(--color-dark);
}

.inputStyles {
  border: 1px solid #b8b8b8;
  border-radius: 15px;
  background-color: var(--color-white);
  color: var(--color-disabled-text);
}

.tap-large {
  background: #d9d9d9;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0;
  height: 192px;
  border-radius: 0 0 21.4881px 21.4881px;
}

.inputStyles::placeholder {
  color: var(--color-disabled-text);
  font-weight: 500;
}

.text-break-white {
  white-space: nowrap !important;
}

.star {
  position: absolute;
  width: 25px;
  right: 50px;
  top: 50%;
  transform: translateY(-50%);
  height: 25px;
  object-fit: cover;
}

.details {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
}

.details img {
  width: 25px;
  height: 25px;
}
</style>
