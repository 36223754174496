<template lang="pug">
  div(class="position-relative w-100 h-100", style="max-width: 100%")
    Header
    .contain
      .box-error
        AlertCircle(class="icon-alert" size="32px")
        span(class="d-block mt-2") Los datos que ingresaste no
        span(class="d-block mb-2") concuerdan con un plan activo
        b-form-input(type="text" class="my-1 form-control-sm form-disable-white" :placeholder="tipideText" :disabled="true")
        b-form-input(type="text" class="my-1 form-control-sm form-disable-white" :placeholder="numide" :disabled="true")
      
      .mt-3.text-center
        b-button(variant="bluecolmedica" class="text-center" size="sm" type="button" @click="goToView('Autenticate')") Ingresar datos nuevamente
      a(class="d-block mt-5 text-center" @click="$bvModal.show('CallTo')") Quiero afiliarme a Colmédica


      b-modal(id="CallTo" footerClass='p-1 border-top-0' bodyClass="p-2" headerClass='p-1 border-bottom-0' centered=true hide-footer hide-header)
        div(class="position-relative w-auto h-auto py-5")
          .d-block.text-center.text-sizing-09.mb-3 Comunícate con nuestra línea de atención comercial y conoce todas las opciones que tenemos para ti y tu familia:
          a(class="d-block text-center text-sizing-2 mt-2" href="tel:3013944550")
            PhoneInTalk(size="35px")
            span 3013944550
          .d-block.text-center.text-sizing-1.mt-3
            div horario de atención
            div lunes a viernes de 7:00AM a 7:00PM
            div sábado de 9:00AM a 1:00PM
          .d-block.text-center.text-sizing-09.mt-3 o ingresa a 
          .d-block.text-center.text-sizing-09
            a(class="link-colmedica" href="https://www.colmedica.com/planes" target="_blank") www.colmedica.com/planes
          .d-block.text-center.text-sizing-09 y solicita una llamada para obtener una asesoría


      .botton-actions
        .d-flex.justify-content-between
          b-button(variant="bluecolmedica" class="text-center" size="sm" type="button" @click="goToView('Autenticate')")
            Back(size="24px")
            span(class="text-sizing-12") Atrás
</template>
<script>
import { mapActions, mapState } from "vuex";
import Header from "./components/Header";
import Back from "mdi-vue/ArrowLeftBold";
import AlertCircle from "mdi-vue/AlertCircle";
import PhoneInTalk from "mdi-vue/PhoneInTalk";
import _get from "lodash/get";

export default {
  name: "ErrorAutenticate",
  components: {
    Header,
    Back,
    AlertCircle,
    PhoneInTalk
  },
  computed: {
    ...mapState({
      b64final: state => state.autoservice.b64final,
      tipideList: state => state.autoservice.tipide
    }),

    numide() {
      return _get(this.b64final, "nu");
    },

    tipide() {
      return _get(this.b64final, "ti");
    },

    tipideText() {
      return _get(
        this.tipideList.find(elm => elm.value === this.tipide),
        "text"
      );
    }
  },
  methods: {
    ...mapActions({
      goToView: "autoservice/goToView"
    })
  }
};
</script>
<style scoped>
.contain {
  margin-top: 50px;
  position: relative;
  width: 100%;
  height: calc(100% - (58px + 22px + 50px));
}
.box-error {
  background-color: #dfe6ed;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
}
.icon-alert {
  color: #d3455b;
}
.form-disable-white:disabled {
  background-color: white;
}

/* Modal */
.colmedica-background {
  background-color: #01a2d8;
  color: white;
  font-weight: 200;
  font-size: 15px;
}

.link-white {
  color: white;
  font-weight: 500;
}
.link-white:link {
  color: white;
  font-weight: 500;
}

.link-colmedica {
  font-weight: bold;
  color: #00599d;
}
.link-colmedica:link {
  font-weight: bold;
  color: #00599d;
}

/* botom actions button - autoservice */
.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 24px;
}
</style>
