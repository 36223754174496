<template lang="pug">
.Container
  //- Home(v-if="view === 'Autenticate'")
  TurnChecker(v-if="view === 'TurnChecker'")
  Autenticate(v-if="view === 'Autenticate'")
  UploadFiles(v-if="view === 'UploadFiles'")
  VerificationData(v-if="view === 'VerificationData'")
  ConfirmUpload(v-if="view === 'ConfirmUpload'")
  ErrorAutenticate(v-if="view === 'ErrorAutenticate'")
  ErrorAutenticateVal(v-if="view === 'ErrorAutenticateVal'")
  BranchValidation(v-if="view === 'BranchValidation'")
  OutOperationsFinish(v-if="view === 'OutOperationsFinish'")
  Afiliate(v-if="view === 'Afiliate'")
  NewUser(v-if="view == 'NewUser'")
  IndexSchedules(v-if="view === 'IndexSchedules'")
  CancelSchedule(v-if="view === 'CancelSchedule'")
  ToSchedule(v-if="view === 'ToSchedule'")
  Calendar(v-if="view === 'Calendar'" title="Agenda tu videollamada")
  Calendar(v-if="view === 'CalendarRepro'" title="Reprogramar")
  ConfirmSchedule(v-if="view === 'ConfirmSchedule'")
  Alerted(v-if="view === 'Alerted'")
  Popup(v-if="view === 'Popup'")
  MeetingMeToGetTurn(v-if="view === 'MeetingMeToGetTurn'")
  .fakeElementToScroll
  p.Version versión: {{ version }}
</template>
<script>
import { mapState, mapActions } from "vuex";
import EvalMobile from "@/utils/evalmobile";
// views
import Home from "./Home";
import TurnChecker from "./TurnChecker";
import Autenticate from "./Autenticate";
import UploadFiles from "./UploadFiles";
import VerificationData from "./VerificationData";
import ConfirmUpload from "./ConfirmUpload";
import ErrorAutenticate from "./ErrorAutenticate";
import BranchValidation from "./BranchValidation";
import OutOperationsFinish from "./OutOperationsFinish";
import ErrorAutenticateVal from "./ErrorAutenticateVal";
import Afiliate from "./Afiliate";
import NewUser from "./NewUser";
import IndexSchedules from "./IndexSchedules";
import ToSchedule from "./ToSchedule";
import CancelSchedule from "./CancelSchedule";
import ConfirmSchedule from "./ConfirmSchedule";
import Calendar from "./Calendar";
import Alerted from "./Alerted";
import MeetingMeToGetTurn from "./MeetingMeToGetTurn";
import Popup from "./Popup";

export default {
  name: "autoservice",
  components: {
    Home,
    TurnChecker,
    Autenticate,
    UploadFiles,
    ConfirmUpload,
    VerificationData,
    ErrorAutenticate,
    BranchValidation,
    OutOperationsFinish,
    ErrorAutenticateVal,
    Afiliate,
    Popup,
    IndexSchedules,
    CancelSchedule,
    ToSchedule,
    Calendar,
    ConfirmSchedule,
    Alerted,
    MeetingMeToGetTurn,
    NewUser
  },
  // data() {
  //   return {
  //     view: "Popup"
  //   };
  // },
  mounted() {
    // tracking
    let trackingData = {
      uuid: window.person.id,
      accion: "Customer - Inicio",
      debmedia_turn_code: "",
      url_origen: window.location.href,
      origen: "ad",
      mensajes: ""
    };

    this.sendTracking(trackingData);
  },
  computed: {
    ...mapState({
      view: state => state.autoservice.view,
      version: state => state.version
    }),

    UsableHeight() {
      return window.innerHeight + "px";
    },
    UsableWidth() {
      return EvalMobile.any() ? "100vw" : "500px";
    }
  },
  methods: {
    ...mapActions({
      sendTracking: "virtualrow/handleTracking"
    })
  }
};
</script>

<style lang="scss">
* {
  white-space: normal !important;
}

.emailText {
  max-width: 80%;
  overflow: hidden;
  text-overflow: clip;
  width: 100%;
  display: block;
}

.headerFixed {
  display: flex;
  position: fixed;
  max-width: 459px;
  background-color: var(--color-primary);
  z-index: 10;
  width: 100%;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
}

.headerMenu {
  position: absolute;
  left: 7px;
  top: 8px;
  z-index: 8000;
}

.color-white {
  color: var(--color-white);
}

.botton-actions-footer {
  background-color: #f2f4f8;
  margin-bottom: -2px;
  max-width: 385.7px !important;
  padding: 0 2rem;
}

.Container {
  max-width: 411px;
  margin: 0 auto;
  position: relative;
  height: 100%;
  padding: 0 30px;
}

.text-start {
  text-align: start;
}

.text-center {
  text-align: center;
}

.text-title {
  font-size: 30px;
  text-align: center;
  padding-right: 12px;
}

.text-dark-cm {
  color: rgb(20, 20, 20);
  font-weight: 500;
}

.green {
  color: var(--color-success);
}

.button-without-styles {
  border: none;
  outline: none;
  padding: 0;
  margin: 0;
  height: fit-content;
  background: none;
  // line-height: 0;
}

.font-md {
  font-size: var(--font-md);
}

.color-dark {
  color: var(--color-dark);
}

canvas[style="display: inline-block; width: 100%; height: 330px; vertical-align: top;"] {
  border-radius: 13px;
  height: 307px !important;
}

.vc-header {
  margin-bottom: 34.53px;
}

.dogEl {
  background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
}

.vc-title {
  font-weight: bold !important;
  text-transform: capitalize;
  font-size: 14.7992px !important;
  line-height: 20px;
}

.vc-weekday,
.vc-svg-icon,
.vc-title {
  color: #333333 !important;
}

.vc-day-content:hover,
.vc-day-content:focus,
.vc-highlight {
  background-color: var(--color-primary) !important;
  color: var(--color-white) !important;
}

.Version {
  position: absolute;
  top: 5px;
  right: 5px;

  font-size: 0.8rem;

  color: var(--color-primary);
}
</style>
