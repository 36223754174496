<template lang="pug">
.Content(style="height: 87%;")
  Header
  .contain(style="height: 87%;")
    .headerMenu
      button(@click="onGoBackSim" type="button").button-without-styles.py-3.px-4.isPopup
        svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#0058A2' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
    .text-container
      .text-center.d-block
        svg(width='32' height='32' viewbox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M16 32C24.8366 32 32 24.8366 32 16C32 7.16345 24.8366 -6.26249e-07 16 -1.39876e-06C7.16345 -2.17128e-06 2.17128e-06 7.16344 1.39876e-06 16C0.00937525 24.8326 7.16733 31.9906 16 32Z' fill='#3290E0')
          path(d='M15.9987 24.9805C17.1446 24.9805 18.0117 24.3824 18.0117 23.4165L18.0117 14.5565C18.0117 13.5895 17.1448 12.9935 15.9987 12.9935C14.8807 12.9935 13.9856 13.6144 13.9856 14.5565L13.9856 23.4165C13.9857 24.3595 14.8807 24.9805 15.9987 24.9805ZM15.9987 10.9974C17.0997 10.9974 17.9957 10.1014 17.9957 8.99949C17.9957 7.8995 17.0997 7.00344 15.9987 7.00344C14.8977 7.00344 14.0026 7.8995 14.0026 8.99949C14.0027 10.1015 14.8977 10.9974 15.9987 10.9974Z' fill='#F4F4F4')
      p.TextSection.mt-2.mb-0.mx-3.color-dark.text-size-s En este canal sólo se autorizan
      p.TextSection.mt-0.mb-0.mx-3.color-dark.text-size-s servicios médicos cubiertos por
      p.TextSection.mt-0.mb-0.mx-3.color-dark.text-size-s tu plan de medicina prepagada.
      p.TextSection.mt-2.mb-0.mx-3.color-dark.text-size-s Para realizar la solicitud de 
      p.TextSection.mt-0.mb-0.mx-3.color-dark.text-size-s medicamentos ambulatorios mediante
      p.TextSection.mt-0.mb-0.mx-3.color-dark.text-size-s Aliansalud EPS debes dirigirte a
      p.TextSection.mt-0.mb-0.mx-3.color-dark.text-size-s las
        strong.mx-1 oficinas de atención,
      p.TextSection.mt-0.mb-0.mx-3.color-dark.text-size-s encuentra la más cercana en
      p.TextSection.mt-0.mb-0.mx-3.color-dark.text-size-s el botón 'Más información'
    .botton-actions-footer
      .d-flex.justify-content-between
        b-button(variant="bluecolmedica" size="sm" type="button" @click="handleContinue").w-100.py-2.text-center.mx-1
          span.font-md Entendido
      a.d-block.text-center(target="_blank" style="text-decoration: none; color: #226DB1" href="https://www.aliansalud.com.co/Paginas/Canales-de-atencion.aspx").TextSection.mt-2.mb-0.mx-3.color-dark Más informacion
</template>
<script>
import { mapActions, mapState } from "vuex";
import Header from "./components/Header";
import Back from "mdi-vue/ArrowLeftBold.vue";
import Continue from "mdi-vue/ArrowRightBold";
import asterisker from "@/helpers/asteriskHidden";
import _get from "lodash/get";

export default {
  name: "PopupEl",
  components: {
    Header,
    Back,
    Continue
  },
  data: () => ({
    isntIntersection: false
  }),
  mounted() {
    // tracking
    setTimeout(() => {
      let auth = !this.client
        ? " autentitacion-no-cliente"
        : "autenticacion-cliente";
      let trackingData = {
        uuid: window.person.id,
        accion: "customer-" + auth,
        debmedia_turn_code: "",
        url_origen: window.location.href,
        origen: _get(this.client, "fu") || "ad",
        mensajes: ""
      };

      this.sendTracking(trackingData);
      this.observer = new IntersectionObserver(this.handlerObserver, {
        threshold: 1.0
      });

      this.observer.observe(document.querySelector("#headerCalendarTitle"));
    }, 1000);
  },
  computed: {
    ...mapState({
      options: state => state.autoservice.tipide,
      client: state => state.autoservice.client,
      b64final: state => state.autoservice.b64final,
      service: state => state.autoservice.service,
      HorarioOutContinue: state => state.autoservice.HorarioOutContinue,
      currentUser: state => state.virtualrow.currentUser
    }),

    selectedOption() {
      return this.options.length
        ? this.options.find(option => option.value === this.b64final.ti).text
        : "";
    },

    docNumber() {
      return asterisker(this.b64final.nu, 0, 4);
    },

    name() {
      let pureName = (_get(this.client, "Nombres") || "").split(" ");
      let pureLastName1 = _get(this.client, "PrimerApellido") || "";
      let pureLastName2 = _get(this.client, "SegundoApellido") || "";
      return [...pureName, pureLastName1, pureLastName2]
        .filter(data => !!data)
        .map(word => word) // Before: asterisker(word, 2, 2)
        .join(" ");
    },

    phone() {
      let pure = _get(this.client, "Celular") || "";
      return asterisker(String(pure), 0, 4);
    },

    email1() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      return `${asterisker(splited[0], 2, 2)}`;
    },

    email2() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      if (splited.length > 0)
        return `@${asterisker(splited[1], 0, splited[1]?.length - 2)}`;
      else return "";
    }
  },
  methods: {
    ...mapActions({
      goToView: "autoservice/goToView",
      setService: "autoservice/setService",
      updateB64Final: "autoservice/updateB64Final",
      sendTracking: "virtualrow/handleTracking",
      setExplanation: "autoservice/setExplanation",
      setIsCorrect: "autoservice/setIsCorrect"
    }),

    onGoBackSim() {
      if (this.service) {
        // force select service again
        this.setService(null);
      } else {
        this.setIsCorrect(false);
      }

      this.goToView("VerificationData");
    },

    handleContinue() {
      this.isntIntersection = false;
      const timer = setTimeout(() => {
        this.observer = new IntersectionObserver(this.handlerObserver, {
          threshold: 1.0
        });

        if (document.querySelector("#headerCalendarTitle2"))
          this.observer.observe(
            document.querySelector("#headerCalendarTitle2")
          );
        clearTimeout(timer);
      }, 10);
      this.isCorrect = !this.isCorrect;
      // this.setExplanation(true);
      this.setIsCorrect(true);
      this.goToView("VerificationData");
    },

    handlerObserver(entries) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          this.isntIntersection = true;
        } else {
          this.isntIntersection = false;
        }
      });
    },

    async toTurn() {
      if (this.currentUser)
        this.updateB64Final({
          na: this.name,
          ma: this.currentUser.PrioridadMarcaValor
        });
      // if (this.HorarioOutContinue) this.goToView("UploadFiles");
      // else {
      //   let to = await getOneUseToken();
      //   let jsonB64 = JSON.stringify({
      //     ...this.b64final,
      //     sv: this.service,
      //     to
      //   });
      //   this.$router.push({
      //     name: "Decoder",
      //     query: { pr: utf8_to_b64(jsonB64) }
      //   });
      // }
    },
    loadData(type, action) {
      this.setService(type);
      if (!action) {
        this.toTurn();
        return this.goToView("IndexSchedules");
      }
      this.goToView("UploadFiles");
    }
  }
};
</script>
<style scoped>
.contain {
  /* margin-top: 50px; */
  /* position: relative; */
  width: 100%;
  /* height: calc(100% - (58px + 22px + 50px)); */
}
.containNew {
  margin-top: 0;
}

/* Zone Actions Verify */
.text-middle {
  margin-top: 25px;
  font-size: 15px;
  text-align: center;
}

.form-data {
  margin-top: 40px;
  background-color: #f9f9f9;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  border-radius: 21.4881px;
}

.actions-zone {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 100px;
}

.min-button {
  min-width: 100px;
}

/* Zone Action Request */
.actions-zone-2 {
  margin-top: 30px;
}

/* botom actions button - autoservice */
.botton-actions {
  position: absolute;
  top: 0;
  /* width: 100%; */
  margin-top: 24px;
  left: 0;
}

.botton-actions-2 {
  position: absolute;
  top: 1.25rem;
  /* width: 100%; */
  left: 0;
}

.botton-actions-footer {
  position: fixed;
  bottom: 0;
  padding-bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 317.7px;
  width: 100%;
  z-index: 200;
}

.button-origen {
  font-size: 1.3rem;
}

.specialText {
  color: var(--color-dark);
}

.inputStyles {
  border: 1px solid #b8b8b8;
  border-radius: 15px;
  background-color: var(--color-white);
  color: var(--color-disabled-text);
}

.text-size-s {
  font-size: 17px;
}

.inputStyles::placeholder {
  color: var(--color-disabled-text);
  font-weight: 500;
}

.text-break-white {
  white-space: nowrap !important;
}

.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
