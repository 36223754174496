<template lang="pug">
.IndexSchedules
  Header
  .headerMenu
    button(@click="() => goToView('VerificationData')" type="button").button-without-styles.py-3.px-4.isPopup
      svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
        path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#0058A2' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
  .withoutShowFinishView(v-if="!isPopup && !loading")
    h2.TitleSection.mt-2.pt-5(v-if="service != 'Centro Médico'" style="color: #025190;") Hemos recibido tu solicitud, en las próximas 12 horas hábiles puedes consultar el estado.
    h2.TitleSection.mt-2.pt-5(v-else style="color: #025190;") Oficina Virtual
    //- TODO: p Azxul claro
    p.TextSection.mt-3.mx-2(v-if="service != 'Centro Médico'" style="color: #025190") Si prefieres, puedes agendar una videollamada con uno de nuestros asesores 
    p.TextSection.mt-3.mx-2(v-else style="color: #025190") Ahora puedes ahorrar tiempo agendando tu videollamada con un asesor
    p.mt-4.text-center.grayTextDesc: strong Nuestro horario de videollamada es de:
    ul.mt-2.text-center
      li.CardVicon.grayTextDesc(style="list-style: none;")
        .Vicon.flex(v-if="branchInfo")
          p.ml-2 {{ splittedMessage(branchInfo.activeBranchMessage) }}
    b-button(
      variant="bluecolmedica"
      @click="() => goToView('ToSchedule')"
    ).w-100.my-2.mt-3.py-2
      span.font-md Agendar Videollamada
    //- button(
    //-   @click="getTurnWaiting()"
    //- ).w-100.button-without-styles.my-2.mt-3
    //-   stack-item.btn-block.align-self-center.mx-auto Tomar turno y esperar
    b-button(
      variant="bluecolmedica"
      class="text-center"
      size="sm"
      @click="() => goToView('MeetingMeToGetTurn')"
      type="button").w-100.py-2.mx-1.mb-1
      span.font-md Toma un turno y espera un asesor
    .botton-actions-footer
      b-button(
        variant="bluecolmedica"
        @click="onFinish"
        style="background-color: #ff0304; border: none;"
      ).mt-2.d-block.text-center.py-2.mb-4.w-100
        span.font-md Finalizar
  h5(v-else-if="!isPopup").text-center.text-middle.color-colmedica-gray.mt-5 Cargando...
    .flexMiddle
      Spinner
  .fakeElementToScroll

  .text-center(v-if="isPopup")
    h2.TitleSection.pl-0.pt-5.pb-4#headerCalendarTitle2 ¡Información!
    p.TextSection.mt-2.mb-0.mx-3.color-dark Este canal es sólo para solicitudes de servicios médicos de medicina prepagada.
      p.TextSection.mt-2.mb-0.mx-3.color-dark 
        | Si requieres  servicios de  Aliansalud EPS, como
        strong.ml-1 Medicamentos ambulatorios
        | , conoce cómo en el botón 'Más información'
      .botton-actions-footer(style="margin-bottom: 500px !important;")
        .d-flex.justify-content-between.mb-3
          b-button(variant="bluecolmedica" size="sm" type="button" @click="handleUnderstand").w-100.py-2.text-center.mx-1
            span.font-md Entendido
        a(href="https://www.aliansalud.com.co/Paginas/Canales-de-atencion.aspx" target="_blank" style="color: #226DB1; text-decoration: underline;").TextSection.pt-4.mb-0.mx-3.color-dark
          strong Más información
</template>

<script>
import { mapActions, mapState } from "vuex";
import _get from "lodash/get";

import asterisker from "@/helpers/asteriskHidden";
import Header from "./components/Header";
import StackItem from "../components/StackItem.vue";
import { getOneUseToken, getOneTimeToken, getBranch, getCompany  } from "@/helpers/Meet";
import { utf8_to_b64 } from "../../utils/decoder";
import Spinner from "../components/Spinner";
import LS from "../../helpers/localStorage";

const axios = require("axios");

export default {
  name: "IndexSchedules",

  data: () => ({
    assingTurn: "A569",
    loading: true,
    showSchedule: 0,
    isPopup: false,
    isCauto: false,
    ma: null
  }),

  async created() {
    this.ma = LS.getItem("marcacion");
    this.loading = true;

    if (!this.ma) {
      this.ma = this.b64final.ma;
    }

    // Check Schedules
    const company = await getCompany(this.env.VUE_APP_COMPANY);
    const branch = _get(
      Object.keys(company).filter(
        name =>
          company[name].branch_id == this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH
      ),
      "[0]"
    );
    const branchInfo = await getBranch(this.env.VUE_APP_COMPANY, branch);
    if (!branchInfo.activeBranch) {
      return this.goToView("OutOperationsFinish");
    }

    console.log("this.ma")
    console.log(this.ma)

    if (this.ma === 1 || this.ma === "1" || this.ma === "cliente") {
      this.isCauto = true;
    } else {
      this.isCauto = false;
    }

    console.log("branchInfo")
    console.log(branchInfo)
    console.log(this.branchInfo)
    if(!this.branchInfo || this.branchInfo && !this.branchInfo.activeBranchMessage){
      this.setBranchInfo(branchInfo);
    }

    // Request to awTime
    axios
      .get(
        `${this.env.VUE_APP_DEBMEDIA_SERVER.replace(
          "/api/v1",
          ""
        )}/api/queues/branchInfo/queue/${
          this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH
        }`
      )
      .then(res => {
        let turnInfo = res.data;
        console.log("turnInfo")
        console.log(turnInfo)
        turnInfo = turnInfo ? turnInfo.monitorInfo.waitingRooms : [];
        turnInfo =
          turnInfo[0].avgWaitingTime == "--"
            ? 0
            : parseInt(turnInfo[0].avgWaitingTime);
        if (turnInfo < this.env.VUE_APP_MAX_WAITING_TIME) {
          this.getTurnWaiting();
        } else {
          this.loading = false;
        }
      });
  },

  async mounted() {

    
    // check for select service
    if(this.b64final.fu.includes('web') || this.b64final.fu.includes('app') ){

      // Only web
      if(this.b64final.fu.includes('web')){
        // check 'MEDICINE'
        if(this.b64final.md && !this.service){
          return this.goToView( "Popup");
        }
  
        // Check services
        if(!this.service){
          // redirect to select service and upload files
          this.goToView('VerificationData')
        }
      }

    } else {

      let to = await getOneTimeToken();
      let ma = 0;
      let fu = this.$route.path.substring(1);
      let su = Number(this.env.VUE_APP_DEBMEDIA_DEFAULT_BRANCH);
      let tr = Number(this.env.VUE_APP_DEBMEDIA_DEFAULT_TRAMITE);
      let jsonFinal = { to, tr, su, fu, ma };
      this.updateB64Final(jsonFinal);
    }
    
    this.video = this.$el.querySelector("#video");

    // this.observer = new IntersectionObserver(this.handlerObserver, {
    //   threshold: 1.0
    // });

    // this.observer.observe(document.querySelector("#headerCalendarTitle"));

    // Check 'Control de medicamentos'
    console.log("MEDICINE");
    console.log(this.client);
    console.log(this.b64final.fu);
    console.log(this.b64final.fu?.includes("web"));
    // if (this.b64final.fu?.includes("web") && this.client.ControlMedicamentos)
      // this.goToView( "Popup");
  },

  components: {
    StackItem,
    Header,
    Spinner
  },

  computed: {
    ...mapState({
      client: state => state.autoservice.client,
      b64final: state => state.autoservice.b64final,
      service: state => state.autoservice.service,
      env: state => state.env,
      branchInfo: state => state.branchInfo
    }),

    phone() {
      let pure = _get(this.client, "Celular") || "";
      return asterisker(String(pure), 0, 4);
    },

    email() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      return `${asterisker(splited[0], 2, 2)}${
        splited[1] ? "@" + asterisker(splited[1], 0, splited[1].length - 2) : ""
      }`;
    }
  }, 

  methods: {
    ...mapActions({
      goToView: "autoservice/goToView",
      clearDocs: "autoservice/clearDocs",
      setClient: "autoservice/setClient",
      updateB64Final: "autoservice/updateB64Final",
      cancelTurn: "cancelTurn",
      setBranchInfo: "setBranchInfo"
    }),

    handlerObserver(entries) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          this.isntIntersection = true;
        } else {
          this.isntIntersection = false;
        }
      });
    },

    async onFinish() {
      localStorage.clear();
      await this.cancelTurnHandler();
      this.goToView("Autenticate");
      this.clearDocs()
    },

    async cancelTurnHandler() {
      try {
        await this.cancelTurn();
      } catch (error) {
        console.error("Cant cancel turn in component", error);
      }
    },

    splittedMessage(msg) {
      if(!msg) return
      return msg.split("Nuestro horario de atención de videollamada es:")[1];
    },

    async getTurnWaiting() {
      let to = await getOneUseToken();
      let jsonB64 = JSON.stringify({
        ...this.b64final,
        sv: this.service,
        to
      });
      this.loading = false;
      this.$router.push({
        name: "Decoder",
        query: { pr: utf8_to_b64(jsonB64) }
      });
    },

    handleUnderstand() {
      this.isPopup = false;
      // this.isCorrect = true;
    }
  }
};
</script>

<style>
.IndexSchedules {
  height: calc(100% - 150px);
}

.AssignmentText {
  font-size: 21px;
  line-height: 28px;
}

.ImportantAsignment {
  color: var(--color-secondary);
}

.mx-2.TextSection.desc {
  margin-top: 38.46px;
}

.mx-2.Link {
  cursor: pointer;
  color: var(--color-secondary);
}

.mx-2.Link:hover {
  text-decoration: none;
  color: var(--color-secondary);
}

.boxTurnAssigned {
  background: #f9f9f9;
  border-radius: 21.4881px;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
}

.showFinishView {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 380px;
}

.buttons-actions {
  position: fixed;
  bottom: 0;
  margin-bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 317.7px;
  width: 100%;
  z-index: 200;
}

.stylesButton {
  align-items: flex-start !important;
}

.grayTextDesc {
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;

  color: #66707e;
}

.StackItem span {
  text-align: start;
}

.buttonBack {
  font-style: normal;
  font-weight: 500 !important;
  font-size: 20.3521px;
  line-height: 27px;

  text-align: center;
  color: #081e3d;
}

.botton-actions-footer {
  position: fixed;
  bottom: 0;
  padding-bottom: 22px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 317.7px;
  width: 100%;
  z-index: 200;
}
</style>
