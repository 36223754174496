<template lang="pug">
.Content
  .Afiliate
    Header
    .botton-actions
      .d-flex.justify-content-between.pl-2
        button(@click="goToView('Autenticate')" type="button").button-without-styles.py-3.px-4
          svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#0058A2' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
    h2.TitleSection.pt-5 Afiliate a Colmédica
    p.TextSection.mb-0.mt-3 Comunícate con nuestra línea de atención comercial y conoce todas las opciones que tenemos para ti y tu familia:
    p.TextSection 301 394 45 50
    p.TextSection.mb-0 El horario de atención para afiliaciones a Colmédica es:
    p.TextSection.mb-0 Lunes a viernes: 7:00 a.m. a 7:00 p.m
    p.TextSection Sábados:  9:00 a.m. a 1:00 p.m.
    p.TextSection.mb-0 O ingresa a nuestro sitio web
    p.text-center.mb-0: a(href="https://www.colmedica.com/planes" target="_blank").Link https:// www.colmedica.com/planes
    p.TextSection.mb-0.px-3 y solicita una llamada para obtener una asesoría.
</template>

<script>
import Header from "./components/Header";
import { mapActions } from "vuex";

export default {
  name: "Afiliate",
  components: {
    Header
  },
  methods: {
    ...mapActions({
      goToView: "autoservice/goToView"
    })
  }
};
</script>

<style lang="scss" scoped>
.botton-actions {
  position: absolute;
  top: 0;
  /* width: 100%; */
  margin-top: 10px;
  left: 0;
}

.Link {
  color: var(--color-secondary);
}
</style>
