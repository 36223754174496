<template lang="pug">
.CancelSchedule
  Header
  div(style="margin-top: 50px")
  .headerFixed(v-if="isntIntersection && !modeCanceled")
    button(@click="() => goToView('ToSchedule')" type="button").button-without-styles.py-3.px-4
      svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
        path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#ffff' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
    h2.TitleSection.text-title.color-white.pt-2 Cancelación de tu videollamada
  h2.TitleSection.pt-3.pb-3(v-if="!modeCanceled")#headerCalendarTitle Cancelación de tu videollamada
  .botton-actions.ml-5(v-if="!modeCanceled")
    .d-flex.justify-content-between
      button(type="button" @click="() => goToView('ToSchedule')").button-without-styles
        svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#0058A2' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
  h2(v-else).TitleSection.pt-4.mb-4.pb-1 Tu videollamada ha sido cancelada
  .form-data.mb-3.mt-3.py-4.mx-2.px-4
    p.font-16.mb-0 Nombre:
      strong.ml-1 {{ appointment.customer.firstName }} 
    p.font-16.mb-0 Fecha y hora:
      strong.ml-1 {{ appointmentDate }}
    p.font-16.mb-0(v-if="appointment.reason.servicioSelected && typeof appointment.reason.servicioSelected == 'object' && Object.keys(appointment.reason.servicioSelected).length") Trámite:
      strong.ml-1 {{ appointment.reason.servicioSelected }}
    p.font-16.mb-0 Modalidad de atención:
      strong.ml-1 {{ appointment.reason.modalidad }}
    a.mx-2.LinkColored.mt-4.pb-1.text-center.d-block Te enviaremos la confirmación de cancelacion a tu correo electrónico
      p.mb-0.ml-1.d-flex.flex-wrap.justify-content-center
        span {{ email1 }}
        span {{ email2 }}
      span.ml-1 y a tu número celular
      span.ml-1 {{ phone }}
  .botton-actions-footer
    .d-flex.justify-content-center
      b-button(
        variant="bluecolmedica"
        class="text-center"
        size="sm"
        type="button"
        @click="handleCancel"
        v-if="!modeCanceled").w-100.py-2.mx-1
        span(v-if="!loading").font-md Cancelar videollamada
        .py-1(v-else)
          MiniSpinner
      div(v-else)
        b-button(
          variant="bluecolmedica"
          @click="() => goToView('ToSchedule')"
        ).mb-2.d-block.text-center.w-100.py-2.mx-1: span(style="color: white;").TextSection
          span.font-md Ver videollamadas programadas
        b-button(
          variant="whitecolmedica"
          class="text-center"
          size="sm"
          type="button"
          @click="() => goToView('Home')").w-100.py-2.mx-1
          span.font-md Ya puedes cerrar esta ventana
</template>

<script>
import { mapActions, mapState } from "vuex";
import asterisker from "@/helpers/asteriskHidden";
import _get from "lodash/get";
import moment from "moment";
import MiniSpinner from "../components/MiniSpinner";

import Header from "./components/Header";

export default {
  name: "CancelSchedule",

  data: () => ({
    name: "Susan Fajardo",
    date: "13/02/2022, 9:00a.m.",
    serviceData: "Cardiologia",
    modeAtt: "Videollamada",
    modeCanceled: false,
    isntIntersection: false,
    loading: false
  }),

  components: {
    MiniSpinner,
    Header
  },

  mounted() {
    this.observer = new IntersectionObserver(this.handlerObserver, {
      threshold: 1.0
    });

    this.observer.observe(document.querySelector("#headerCalendarTitle"));
  },

  computed: {
    ...mapState({
      client: state => state.autoservice.client,
      b64final: state => state.autoservice.b64final,
      service: state => state.autoservice.service,
      appointment: state => state.appointments.appointment,
      env: state => state.env
    }),

    phone() {
      let pure = _get(this.client, "Celular") || "";
      return asterisker(String(pure), 0, 4);
    },

    email1() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      return `${asterisker(splited[0], 2, 2)}`;
    },

    email2() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      return `${
        splited[1] ? "@" + asterisker(splited[1], 0, splited[1].length - 2) : ""
      }`;
    },

    appointmentDate() {
      return moment(this.appointment.startAt).format("DD/MM/YYYY hh:mm a");
    }
  },

  methods: {
    ...mapActions({
      goToView: "autoservice/goToView",
      updateAppointment: "appointments/updateAppointment"
    }),

    handlerObserver(entries) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          this.isntIntersection = true;
        } else {
          this.isntIntersection = false;
        }
      });
    },

    async handleCancel() {
      console.log(this.appointment);
      let data = {
        branch: this.appointment.branch?.id,
        status: "CANCELED",
        schedule: this.appointment.schedule?.id,
        code: this.appointment.code,
        startAt: this.appointment.startAt
      };

      this.modeCanceled = false;
      this.loading = true;
      await this.updateAppointment({
        url: this.env.VUE_APPOINTMENTS_DEBMEDIA_SERVER,
        headers: {
          token_appointments: this.env.VUE_APPOINTMENTS_DEBMEDIA_API_KEY
        },
        queryData: "",
        data: data
      });
      this.loading = false;
      this.modeCanceled = true;
    }
  }
};
</script>

<style scoped>
.botton-actions {
  position: absolute;
  top: 1.25rem;
  /* width: 100%; */
  left: -1rem;
}

.form-data {
  margin-top: 40px;
  background-color: #f9f9f9;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  border-radius: 21.4881px;
}

.LinkColored {
  cursor: pointer;
  color: var(--color-secondary);
  line-height: 20px;
  margin-top: 28.44px;
}

.botton-actions-footer {
  position: fixed;
  bottom: 0;
  padding-bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 317.7px;
  width: 100%;
  z-index: 200;
}

.LinkUnderlined {
  color: var(--color-gray);
  text-decoration: underline;
}
</style>
