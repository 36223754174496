<template lang="pug">
//- .MeetingMeToGetTurn
//-   .headerFixed(v-if="isntIntersection")
//-     button(@click="() => goToView('Home')" type="button").button-without-styles.py-3.px-4
//-       svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
//-         path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#ffff' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
//-     h2.TitleSection.text-start.color-white.pt-2 Toma un turno y hacemos la fila por ti
//-   h2.TitleSection.text-start.pl-2.ml-4.pt-3.pb-4.pr-5#headerTitleMMToGetTurn
//-     | Toma un turno y hacemos la fila por ti
//-   .botton-actions.ml-5
//-     .d-flex.justify-content-between
//-       button(type="button" @click="() => goToView('Home')").button-without-styles
//-         svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
//-           path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#0058A2' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
//-   .form-data.mb-3.mt-5.pb-4.mx-2.px-3.pt-5
//-     p.text-center.mt-3.pb-3 Toma un turno y te enviaremos un correo electrónico y un mensaje de texto con un link de ingreso a la videollamada cuando tu turno esté próximo a ser atendido
//-     b-button(
//-       variant="bluecolmedica"
//-       class="text-center"
//-       size="sm"
//-       type="button"
//-       @click="getTurnWaiting()").w-100.py-2.mb-3.mr-1
//-       span.font-md Tomar turno
//-     a.mx-2.LinkColored.mt-4.pb-1.text-center.d-block.mb-4 La notificación te la enviaremos a tu correo electrónico
//-       p.mb-0.ml-1.d-flex.flex-wrap.justify-content-center
//-         span {{ email1 }}
//-         span {{ email2 }}
//-       span.ml-1 y a tu número celular
//-       span.ml-1 {{ phone }}
</template>

<script>
import { mapActions, mapState } from "vuex";
import _get from "lodash/get";

import asterisker from "@/helpers/asteriskHidden";
import { getOneUseToken } from "../../helpers/Meet";
import { utf8_to_b64 } from "../../utils/decoder";

export default {
  name: "MeetingMeToGetTurn",

  data() {
    return {
      observer: null,
      isntIntersection: false
    };
  },

  mounted() {
    this.getTurnWaiting();
    // this.observer = new IntersectionObserver(this.handlerObserver, {
    //   threshold: 1.0
    // });

    // this.observer.observe(document.querySelector("#headerTitleMMToGetTurn"));
  },

  // FIXME: Add two views for a complete service TIKTOK AND TURN

  computed: {
    ...mapState({
      client: state => state.autoservice.client,
      b64final: state => state.autoservice.b64final,
      service: state => state.autoservice.service
    }),

    phone() {
      let pure = _get(this.client, "Celular") || "";
      return asterisker(String(pure), 0, 4);
    },

    email1() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      return `${asterisker(splited[0], 2, 2)}`;
    },

    email2() {
      let pure = _get(this.client, "Correo") || "";
      let splited = pure.split("@");
      return `${
        splited[1] ? "@" + asterisker(splited[1], 0, splited[1].length - 2) : ""
      }`;
    }
  },

  methods: {
    ...mapActions({
      goToView: "autoservice/goToView"
    }),

    async getTurnWaiting() {
      let to = await getOneUseToken();

      let b64final = { ...this.b64final };

      b64final.na = b64final.na ? b64final.na : this.client.Nombres;
      b64final.ap = b64final.ap
        ? b64final.ap
        : this.client.PrimerApellido + " " + this.client.SegundoApellido;
      b64final.em = b64final.em ? b64final.em : this.client.Correo;
      b64final.ce = b64final.ce ? b64final.ce : this.client.Celular;

      console.log(b64final);

      let jsonB64 = JSON.stringify({
        ...b64final,
        sv: this.service,
        to
      });

      this.$router.push({
        name: "Decoder",
        query: { pr: utf8_to_b64(jsonB64) }
      });
    },

    handlerObserver(entries) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          this.isntIntersection = true;
        } else {
          this.isntIntersection = false;
        }
      });
    }
  }
};
</script>

<style sccoped>
.MeetingMeToGetTurn {
  color: black;
}

.botton-actions {
  position: absolute;
  top: 1.25rem;
  /* width: 100%; */
  left: -1rem;
}

.form-data {
  margin-top: 40px;
  background-color: #f9f9f9;
  filter: drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.1));
  border-radius: 21.4881px;
}
</style>
