<template lang="pug">
.Content
  Header
  .contain
    h2.TitleSection.ml-2.pt-1#headerCalendarTitle Adjunta documentos
    .headerFixed(v-if="isntIntersection")
      button(@click="documents.length == 0 ? backView() : $bvModal.show('ConfirmDelete')" type="button").button-without-styles.py-3.px-4
        svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
          path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#ffff' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
      h2.TitleSection.text-title.color-white.pt-2 Adjunta documentos
    .botton-actions.ml-4.pl-2.pt-1
      .d-flex.justify-content-between
        button(type="button" @click="documents.length == 0 ? backView() : $bvModal.show('ConfirmDelete')").button-without-styles
          svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
            path(d='M9.55301 16.8398L2.26586 9.55269L9.55301 2.26554M3.27796 9.55269L18.0547 9.55269' stroke='#0058A2' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
    div
      p.text-start.font-16.mr-5.color-descartDocumentBack.DescDocs.mt-3(v-if="!documents.length") Por favor adjunta los siguientes documentos:
      ol.olContainer(v-if="!documents.length")
        li.TextSection.text-start.listItem Foto de orden médica una cara y/o foto de frente y respaldo de solicitud de servicios médicos
        li.TextSection.text-start.listItem Resultado de exámenes relacionados con la solicitud (en caso de tenerlos)
        li.TextSection.text-start.listItem Copia de la historia clínica (en caso de tenerla a la mano)
      .ordenMedica
        h3(:style="statusOrdenStyles").mb-2.mt-4.d-flex
          span.mr-2.d-flex(style="width: 25px; height: 25px; border-radius: 50%; background-color: rgb(104 169 95); color: white;" v-if="documents.length")
            Check
          span {{ statusOrden }}
        p.TextSection.text-center {{ statusOrdenDescription }}
      .addDocument
        .addDocumentContainer.smallContainer.mx-0.text-center(@click="simulateImagen" v-if="!documents.length")
          div
            svg(width='56' height='56' viewbox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg')
              path(d='M28.1138 5L28.1138 51' stroke='#8f9caf' stroke-width='10' stroke-linecap='round')
              path(d='M5 28H51' stroke='#8f9caf' stroke-width='10' stroke-linecap='round')
            p(style="font-size: 20px; line-height: 20px;").m-0.mt-3 Adjuntar
            p(style="font-size: 20px;").m-0 Documento
        .glider-contain.documents-zone
          vue-slick-carousel(:slidesToScroll="2" :slidesToShow="2" :list="convertDataMapped" v-if="documents.length")
            .slick-item(
              v-for="(snap, key2) in convertDataMapped"
              :key="'col_' + key2"
              :id="key2 + 1"
              :class="convertDataMapped.length === 1 && 'centeredUniqElement'"
              v-if="!snap.isContainerToAdd"
            )
              i.icon.fa.fa-times-circle(@click="removeScan(key2)")
              img(v-if="snap.isImg" :src="snap.file" class="snapshot" @click="showImageInModal(snap)")
              .snapshot(v-else @click="showImageInModal(snap)")
                pdf(:src="snap.file")
              p.TextSection.text-truncate.w-100.descP.mt-1.text-start {{ snap.info.name }}
            .slick-item.addDocumentContainer.position-relative(@click="simulateImagen" :id="convertDataMapped.length")
              img(v-if="convertDataMapped[0].isImg" :src="convertDataMapped[0].file" class="snapshotHidden")
              .position-absolute
                svg(width='56' height='56' viewbox='0 0 56 56' fill='none' xmlns='http://www.w3.org/2000/svg')
                  path(d='M28.1138 5L28.1138 51' stroke='#8f9caf' stroke-width='10' stroke-linecap='round')
                  path(d='M5 28H51' stroke='#8f9caf' stroke-width='10' stroke-linecap='round')
                p(style="font-size: 20px; line-height: 20px;").m-0.mt-3 Adjuntar
                p(style="font-size: 20px;").m-0 Documento
          div(v-if="documents.length")
            span.red 
              b ¡
            span.blue
              b Tus documentos se han adjuntado con éxito
            span.red
              b !
    .botton-actions-footer(v-if="documents.length")
      .d-flex.justify-content-between
        b-button(variant="bluecolmedica" @click="goToView('ConfirmUpload')").text-center.w-100.py-2.buttonTitle.position-relative
          | Enviar solicitud
    div(v-show="false")
      input(type="file" accept="image/*" style="display: none" ref="image" @change="fileHandlerImage")
      input(type="file" accept="*/*" style="display: none" ref="file" @change="fileHandlerGeneral")

    .ModalContent#AddDocument
      .position-relative.w-auto.h-100
        .position-relative.pt-4.d-flex.justify-content-between.pb-2
          button(@click="() => descartDocumentBack(true)").button-without-styles.ml-4
            svg(width='20' height='19' viewbox='0 0 20 19' fill='none' xmlns='http://www.w3.org/2000/svg')
              path(d='M9.28739 16.5742L2.00024 9.28706L9.28739 1.99991M3.01234 9.28706L17.7891 9.28707' stroke='#0058A2' stroke-width='3.12307' stroke-linecap='round' stroke-linejoin='round')
          span
        .d-block.text-center.Background
          figure(v-if="holdIsImagen").m-0.containerImage.mx-auto.block.pt-5.px-3
            img(
              :src="urlData"
              alt="imageUser"
              ref="fileImage"
              v-show="cropper"
            ).object-fit-cover.w-100.h-100.p-16.imageModal#generalImage
          .pt-4.mb-4.TextSection(v-if="!holdIsImagen") Sin vista previa del documento
          div(v-if="!holdIsImagen")
            b-button(
              variant="bluecolmedica"
              @click="addDocument()"
              v-if="!modeCropper"
              size="sm").mb-4 Usar imagen
          .zone-button(v-if="holdIsImagen")
            .mx-5
              div(v-if="compressImagen == false")
                .p-2.d-flex.justify-content-around.w-100.mt-3.mb-1(v-if="!modeCropper")
                  .circleCard.mr-3.d-flex.text-center
                    div(@click="() => onRotation(-90)")
                      svg(width='16' height='20' viewbox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg')
                        path(d='M2.09 12.5C2.568 15.333 5.033 17.5 8 17.5C11.308 17.5 14 14.808 14 11.5C14 8.192 11.308 5.5 8 5.5H5.828L7.914 7.586L6.5 9L2 4.5L6.5 0L7.914 1.414L5.828 3.5H8C10.1217 3.5 12.1566 4.34285 13.6569 5.84315C15.1571 7.34344 16 9.37827 16 11.5C16 13.6217 15.1571 15.6566 13.6569 17.1569C12.1566 18.6571 10.1217 19.5 8 19.5C3.921 19.5 0.562 16.445 0.0690002 12.5H2.09Z' fill='#F4F4F4')
                    p.textCard.mt-1 Izquierda
                  .circleCard.ml-2.d-flex.text-center
                    div(@click="() => onRotation(90)")
                      svg(width='16' height='20' viewbox='0 0 16 20' fill='none' xmlns='http://www.w3.org/2000/svg')
                        path(d='M13.91 12.5C13.432 15.333 10.967 17.5 8 17.5C4.692 17.5 2 14.808 2 11.5C2 8.192 4.692 5.5 8 5.5H10.172L8.086 7.586L9.5 9L14 4.5L9.5 0L8.086 1.414L10.172 3.5H8C5.87827 3.5 3.84344 4.34285 2.34315 5.84315C0.842855 7.34344 0 9.37827 0 11.5C0 13.6217 0.842855 15.6566 2.34315 17.1569C3.84344 18.6571 5.87827 19.5 8 19.5C12.079 19.5 15.438 16.445 15.931 12.5H13.91Z' fill='#F4F4F4')
                    p.textCard.mt-1 Derecha
                  .circleCard.ml-4.d-flex.text-center
                    div(@click="activateCropper()")
                      svg(width='22' height='23' viewbox='0 0 22 23' fill='none' xmlns='http://www.w3.org/2000/svg')
                        path(d='M18.5833 3.375H19.5833C19.5833 2.82272 19.1356 2.375 18.5833 2.375V3.375ZM0 4.375H18.5833V2.375H0V4.375ZM17.5833 3.375V22.5H19.5833V3.375H17.5833Z' fill='#F4F4F4')
                        path(d='M3.71484 18.2461L20.9597 1.00123' stroke='#F4F4F4' stroke-width='2' stroke-linecap='round' stroke-linejoin='round')
                        path(d='M3.41667 18.543H2.41667C2.41667 19.0953 2.86438 19.543 3.41667 19.543V18.543ZM22 17.543L3.41667 17.543V19.543L22 19.543V17.543ZM4.41667 18.543L4.41667 0H2.41667L2.41667 18.543H4.41667Z' fill='#F4F4F4')
                    p.textCard.mt-1 Recortar
                b-button(
                  variant="bluecolmedica"
                  @click="addDocument()"
                  v-if="!modeCropper").mb-4
                  span.font-md Usar imagen
                .p-2.d-flex.justify-content-around.w-100.mt-3.mb-1(v-else)
                  .circleCard.mr-3.d-flex.text-center
                    div(@click="onCancel()")
                      svg(width='20' height='21' viewbox='0 0 20 21' fill='none' xmlns='http://www.w3.org/2000/svg').svgCloseIcon
                        path(d='M2.49023 17.7656L17.2565 2.99936' stroke='#F4F4F4' stroke-width='4.55622' stroke-linecap='round' stroke-linejoin='round')
                        path(d='M2.49023 3.23438L17.2565 18.0006' stroke='#F4F4F4' stroke-width='4.55622' stroke-linecap='round' stroke-linejoin='round')
                    p.textCard.mt-1 Cancelar
                  .circleCard.ml-2.d-flex.text-center
                    div(@click="onAccept()")
                      svg(width='22' height='16' viewbox='0 0 22 16' fill='none' xmlns='http://www.w3.org/2000/svg')
                        path(d='M2 8L8 14L20 2' stroke='#f4f4f4' stroke-width='3.12' stroke-linecap='round' stroke-linejoin='round')
                    p.textCard.mt-1 Aceptar
    b-modal(id="ShowImage" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header)
      div(class="position-relative w-auto h-auto")
        div(class="d-block text-center")
          img(:src="holdDocument" class="img-fluid max-image-hold" v-if="holdDocument")
          div(v-else) Sin vista previa del documento
        div(class="position-relative mt-3")
          b-button(@click="hiddenImageInModal" variant="bluecolmedica" size="sm" class="text-sizing-12") close

    b-modal(id="ConfirmDelete" footerClass='p-2 border-top-0' headerClass='p-2 border-bottom-0' centered=true hide-footer hide-header)
      div(class="position-relative w-auto h-auto")
        div(class="d-block text-center")
          div(class="text-sizing-108") Al ir Atrás, perderas las fotos cargadas. Estas seguro?
        div(class="position-relative mt-3")
          b-button(@click="backView()" variant="bluecolmedica" size="sm" class="d-block mx-auto my-1 text-sizing-12") Si, ir Atrás
          b-button(@click="$bvModal.hide('ConfirmDelete')" variant="bluecolmedica" size="sm" class="d-block mx-auto my-1 text-sizing-12") No cancelar
  b-modal(
    id="invalid-mime"
    footerClass='p-2 border-top-0'
    headerClass='p-2 border-bottom-0'
    centered=true
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
  )
    h6 Puedes cargar documentos de maximo 10Mb de peso, y con formato: pdf, png, jpg, jpeg y tiff
    h6.text-error Error: {{ errorModalType ? "Su documento no tiene el formato pdf, png, jpg, jpeg o tiff" : "Su documento pesa más de 10MB" }}
    .buttons.mt-4.mimeErrorCloser
      b-button.ml-2(@click="toogleInvalidModal") Cerrar

  b-modal(
    id="invalid-size"
    footerClass='p-2 border-top-0'
    headerClass='p-2 border-bottom-0'
    centered=true
    hide-footer
    no-close-on-esc
    no-close-on-backdrop
    hide-header-close
  )
    h6 No puedes cargar este archivo. El tamaño máximo del archivo es de 10 MB.
    .buttons.mt-4.sizeErrorCloser
      b-button.ml-2(@click="toogleInvalidModal") Cerrar
</template>
<script>
import { mapActions, mapState } from "vuex";
import chunk from "lodash/chunk";
import Header from "./components/Header";
import Spinner from "../components/Spinner";
// import { RlCarousel, RlCarouselSlide } from "vue-renderless-carousel";
import VueSlickCarousel from "./components/VueSlickCarousel";
// icons
import Camera from "mdi-vue/Camera.vue";
import Attach from "mdi-vue/Attachment.vue";
import Rotate from "mdi-vue/RotateRight";
import Back from "mdi-vue/ArrowLeftBold.vue";
import Continue from "mdi-vue/ArrowRightBold.vue";
import Cogs from "mdi-vue/Cogs";
import Check from "mdi-vue/Check";

import Compressor from "compressorjs";
import "@/assets/cropper.css";
import Cropper from "cropperjs";
import pdf from "vue-pdf";

export default {
  name: "UploadFiles",
  components: {
    Header,
    Camera,
    Attach,
    Rotate,
    Back,
    Continue,
    Cogs,
    Spinner,
    VueSlickCarousel,
    Check,
    pdf
    // VueSlickCarousel
  },
  mounted() {
    this.observer = new IntersectionObserver(this.handlerObserver, {
      threshold: 1.0
    });

    this.observer.observe(document.querySelector("#headerCalendarTitle"));
  },
  watch: {
    convertDataMapped(val) {
      if (val && val.length) {
        const timer = setTimeout(() => {
          const $vueSlickCarousel = document.getElementById("VueSlickCarousel");
          $vueSlickCarousel?.scrollTo(
            $vueSlickCarousel.scrollWidth - 174.43,
            0
          );
          clearTimeout(timer);
        }, 10);
      }
    }
  },
  data: () => ({
    holdIsImagen: false,
    holdDocument: null,
    holdDocumentInfo: null,
    compressImagen: false,
    didGo: false,
    observations: "",

    infoSizeImagen: null,

    widthBox: null,
    heightBox: null,
    urlData: "",
    cropper: null,
    modeCropper: false,
    slide: 0,
    errorModalType: false,

    validMimeTypes: [
      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      // "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/msword",
      "application/octet-stream",
      "image/",
      // "video/",
      // "audio/",
      "application/pdf"
    ],

    loadingCompressor: false,
    rotateCompressorError: false,
    isntIntersection: false
  }),
  computed: {
    ...mapState({
      documents: state => state.autoservice.files
    }),
    convertData() {
      return chunk(this.documents, 2);
    },
    convertDataMapped() {
      return this.documents
        ? [
            ...this.documents.map(data => data),
            {
              isContainerToAdd: true
            }
          ]
        : [];
    },
    changedTitle() {
      return this.documents?.length
        ? "Enviar documentos"
        : "Adjunta documentos";
    },
    statusOrden() {
      return this.documents?.length < 2 || !this.documents?.length
        ? "Orden médica y documentos"
        : "Orden médica y documentos";
    },
    statusOrdenDescription(){
      return this.documents?.length < 2 || !this.documents?.length 
      ? "Si tienes varias ordenes con el mismo prestador, y es el mismo tipo de autorización, puedes adjuntarlas todas aquí."
      : "Si los tienes, adjunta la historia clínica y/o los resultados de exámenes anteriores realizados en el último año"
    },
    statusOrdenStyles() {
      return !this.documents?.length
        ? `color: #0058A2; font-size: 20px; font-weight: bold;`
        : `color: rgb(104 169 95); font-size: 20px; font-weight: bold;`;
    }
  },
  methods: {
    ...mapActions({
      goToView: "autoservice/goToView",
      removeScan: "autoservice/removeFile",
      addScan: "autoservice/addFile",
      clearDocs: "autoservice/clearDocs",
      putLog: "log/put"
    }),

    toogleInvalidModal() {
      this.$bvModal.hide("invalid-mime");
      this.$bvModal.hide("invalid-size");
    },

    handlerObserver(entries) {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          this.isntIntersection = true;
        } else {
          this.isntIntersection = false;
        }
      });
    },

    // simulate Attach
    async simulateAttach() {
      this.simulateClick(this.$refs.file);
    },
    async simulateImagen() {
      this.simulateClick(this.$refs.image);
    },
    onRotation(deg) {
      this.cropper.rotate(deg);
    },
    onCancel() {
      this.modeCropper = false;
      this.initialCropper();
    },
    onAccept() {
      const newUrl = this.cropper.getCroppedCanvas().toDataURL("image/jpeg");

      this.urlData = newUrl;
      const timer = setTimeout(() => {
        this.onCancel();
        clearTimeout(timer);
      }, 0);
    },
    activateCropper() {
      const newUrl = this.cropper.getCroppedCanvas().toDataURL("image/jpeg");

      this.modeCropper = true;
      this.urlData = newUrl;

      const timeout = setTimeout(() => {
        this.descartDocumentBack(false);
        this.cropper = new Cropper(this.$refs.fileImage, {
          autoCrop: true,
          viewMode: 2,
          dragMode: "move",
          background: false,
          cropBoxMovable: true,
          cropBoxResizable: true,
          crop(event) {
            this.widthBox = event.detail.x;
            this.heightBox = event.detail.y;
          }
        });
        clearTimeout(timeout);
      }, 10);
    },
    simulateClick(elem) {
      var evt = new MouseEvent("click", {
        bubbles: true,
        cancelable: true,
        view: window
      });
      elem.dispatchEvent(evt);
    },
    // handlers
    fileHandlerImage(event) {
      this.fileHandler(event.target);
    },
    fileHandlerGeneral() {
      this.fileHandler(this.$refs.file);
    },
    async fileHandler(reference) {
      const files = reference.files;
      if (!files[0]) return;
      const infoFile = {
        name: files[0].name,
        ext: files[0].name.split(".")[files[0].name.split(".").length - 1],
        mime: files[0].type,
        size: files[0].size
      };
      const isValidMime = this.validMimeTypes.reduce(
        (prev, curr) => infoFile.mime.indexOf(curr) === 0 || prev,
        false
      );
      const isValidSize = infoFile.size <= 10000000;
      // it was for isValidSize var: infoFile.size < 10000000
      if (!isValidMime) {
        this.putLog({
          name: "UploadFiles. Tipo de archivo inválido.",
          checked: false
        });
        this.errorModalType = true;
        this.$bvModal.show("invalid-mime");
        return;
      }
      if (!isValidSize) {
        this.putLog({
          name: "UploadFiles. Peso de archivo inválido.",
          checked: false
        });
        // this.$bvModal.show("invalid-size");
        this.errorModalType = false;
        this.$bvModal.show("invalid-mime");
        return;
      }
      this.holdIsImagen = files[0].type.indexOf("image") !== -1;
      this.holdDocumentInfo = infoFile;

      this.compressImagen = true;
      let fileToCompress = {};
      if (this.holdIsImagen) {
        try {
          fileToCompress = await this.compressorJsImage(files[0]);
        } catch (error) {
          fileToCompress = files[0];
        }
        this.urlData = URL.createObjectURL(fileToCompress);

        const timeout = setTimeout(() => {
          this.initialCropper();
          clearTimeout(timeout);
        }, 10);

        this.holdDocument = await this.readed(fileToCompress);
      } else {
        this.holdDocument = await this.readed(files[0]);
      }
      this.putLog({
        name: "UploadFiles. Archivo comprimido."
      });
      this.infoSizeImagen = {
        uncompress: infoFile.size,
        compress: fileToCompress.size || infoFile.size
      };
      this.compressImagen = false;
      document.querySelector("#AddDocument").style.display = "block";
      reference.value = "";
    },

    descartDocumentBack(discard) {
      this.cropper?.destroy();
      this.cropper = null;
      if (discard) this.descartDocument();
    },

    initialCropper() {
      this.descartDocumentBack(false);
      this.cropper = new Cropper(this.$refs.fileImage, {
        autoCrop: false,
        viewMode: 2,
        dragMode: "move",
        background: false,
        cropBoxMovable: true,
        cropBoxResizable: true,
        crop(event) {
          this.widthBox = event.detail.x;
          this.heightBox = event.detail.y;
        }
      });
    },

    readed(file) {
      return new Promise((res, rej) => {
        var reader = new FileReader();
        reader.onload = () => res(reader.result);
        reader.onerror = err => rej(err);
        reader.readAsDataURL(file);
      });
    },

    compressorJsImage(file) {
      return new Promise((res, rej) => {
        new Compressor(file, {
          quality: 0.6,
          success(result) {
            res(result);
          },
          error(err) {
            rej(err);
          }
        });
      });
    },

    addDocument() {
      if (this.cropper) {
        this.addScan({
          info: {
            ...this.holdDocumentInfo,
            b64: this.cropper
              .getCroppedCanvas()
              .toDataURL("image/jpeg")
              .replace(/data:[\w/]+;base64,/g, "")
          },
          file: this.cropper.getCroppedCanvas().toDataURL("image/jpeg"),
          isImg: this.holdIsImagen,
          dataImage: this.cropper.getCanvasData()
        });
      } else {
        this.addScan({
          info: {
            ...this.holdDocumentInfo,
            b64: this.holdDocument.replace(/data:[\w/]+;base64,/g, "")
          },
          file: this.holdDocument,
          isImg: this.holdIsImagen
        });
      }
      this.descartDocument();
    },

    descartDocument() {
      document.querySelector("#AddDocument").style.display = "none";
      this.holdDocument = null;
    },

    async rotateImage() {
      this.loadingCompressor = true;
      try {
        const rotateData = await new Promise(res =>
          this.rotate(this.holdDocument, 90, res)
        );
        this.loadingCompressor = false;
        this.holdDocument = rotateData;
      } catch (error) {
        this.loadingCompressor = false;
        this.rotateCompressorError = true;
        console.error("Cant compress image", error);
      }
    },

    rotate(srcBase64, degrees, callback) {
      const canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");
      let image = new Image();
      image.onload = function() {
        canvas.width = degrees % 180 === 0 ? image.width : image.height;
        canvas.height = degrees % 180 === 0 ? image.height : image.width;
        ctx.translate(canvas.width / 2, canvas.height / 2);
        ctx.rotate((degrees * Math.PI) / 180);
        ctx.drawImage(image, image.width / -2, image.height / -2);
        callback(canvas.toDataURL());
        this.loadingCompressor = false;
      };
      image.src = srcBase64;
    },

    showImageInModal(snap) {
      this.holdIsImagen = snap.isImg;
      this.holdDocument = snap.file;
      this.$bvModal.show("ShowImage");
    },

    hiddenImageInModal() {
      this.$bvModal.hide("ShowImage");
      this.holdIsImagen = null;
      this.holdDocument = null;
    },

    backView() {
      this.clearDocs();
      this.goToView("VerificationData");
    }
  }
};
</script>

<style scoped>
.textMessage {
  font-size: 1.1rem;
  color: gray;
}

.contain {
  /* position: relative; */
  width: 100%;
  margin-top: 50px;
  height: calc(100% - (58px + 22px + 50px));
}

/* Zone Documents */
.documents-zone {
  position: relative;
  /* overflow-y: auto; */
  width: 100%;
  /* height: 34%; */
}

/* snapshot container */
.snapshot-row {
  position: relative;
  width: auto;
  height: 120px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 28px;
}

.snapshot-col {
  width: calc(50% - 10px);
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  /* padding: 5px; */
}

.snapshot,
.snapshotHidden {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.snapshotHidden {
  visibility: hidden;
}

.icon {
  position: absolute;
  top: -10px;
  right: -10px;
  font-size: 24px;
  background: white;
  color: #e2574c;
  border-radius: 50%;
  cursor: pointer;
}

.centeredUniqElement {
  margin-left: 51.5px;
}

/* Button Zone */
.zone-button {
  position: fixed;
  bottom: 0;
  max-width: 411px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 25px 25px 0 0;
  background-color: #081e3d;
  width: 100%;
  /* position: relative; */
}

.TitleZoned {
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translateX(-50%);
  max-width: fit-content;
  max-width: 411px;
  width: 100%;
  padding: 7px 0px;
  background-color: var(--color-white);
}

.size-buttons svg {
  fill: #00599d;
}

.size-buttons {
  margin: 0px auto;
  position: relative;
  display: block;
}

.icon-container {
  border-radius: 10px;
  margin: 10px;
  padding: 10px 0px;
  border: 1px solid #ececec;
  width: auto;
  color: rgb(114, 114, 114);
  background: #ececec;
}

.listItem {
  line-height: 20px;
}

.olContainer {
  margin: 0px 18px;
  margin-right: 20px;
}

.max-image-hold {
  height: auto;
  width: 100%;
  max-width: 70vw;
  max-height: 70vh;
  object-fit: cover;
}

/* botom actions button - autoservice */
.botton-actions {
  position: absolute;
  top: 1.25rem;
  /* width: 100%; */
  left: 0;
}

.containerIcons {
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--color-white);
  display: flex;
  justify-content: center;
  align-items: center;
}

.circleCard {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.circleCard div {
  cursor: pointer;
}

.textCard {
  color: #768191;
}

.ModalContent {
  position: fixed;
  z-index: 200;
  left: 50%;
  transform: translateX(-50%);
  height: 100%;
  top: 0;
  background-color: var(--color-white);
  display: none;
  width: 100%;
  max-width: 420px;
  overflow: auto;
  max-height: 100%;
}

/* .Overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 199;
  background-color: ;
} */

.containerImage {
  height: 100%;
  width: 100%;
  max-height: calc(100% - 152px);
  max-width: 420px;
}

.containerImage img {
  display: block;

  /* This rule is very important, please don't ignore this */
  max-width: 500px;
  max-height: 650px;
  width: 100%;
  object-fit: cover;
}

.number-input {
  display: block;
  font-size: 14px;
  text-align: start;
  width: 100%;
  border-radius: 10px;
  height: auto;
  font-weight: bold;
  font-size: var(--font-sm);
  background: none;
  /* background-color: var(--color-white); */
  border: 1px solid var(--color-gray);
  color: var(--color-dark);
}

.number-input:focus {
  outline: 0.1px solid black;
}

.Background {
  background: #081e3d;
  height: calc(100% - 56px);
}

.botton-actions-footer {
  position: fixed;
  bottom: 0;
  padding-bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  max-width: 317.7px;
  width: 100%;
  z-index: 200;
}

.buttonTitle {
  font-size: 20.35px;
}

.iconCenteredArrow {
  right: 17.2px;
  top: 50%;
  transform: translateY(-50%);
}

.descP {
  white-space: nowrap !important;
}

.DescDocs {
  font-size: 18px;
  line-height: 24px;

  /* RESPUESTAS AND ACCENT */

  color: #081e3d;
}

.fakeElementToScrollTwo {
  height: 50px;
}

.text-error {
  color: #e2574c;
}

.addDocumentContainer {
  border: 3px dashed #8f9caf;
  border-radius: 13px;
  width: 100%;
  max-width: 351px;
  margin-left: 45.45px;
  height: 246.41px;
  font-weight: bold;
  cursor: pointer;
  color: #8f9caf;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.smallContainer {
  max-width: 167px;
  height: 196.41px;
}

@media (max-width: 600px) {
  .contain {
    margin-top: 0px;
    height: calc(100% - (58px + 22px));
  }
}

.blue {
  color: rgb(5, 169, 221);
}

.red {
  color: #ff0304;
}
</style>
