<template lang="pug">
.Content
  Header
  .contain
    .text-center.d-block
      svg(width='32' height='32' viewbox='0 0 32 32' fill='none' xmlns='http://www.w3.org/2000/svg')
    p.TextSection.mt-2.mb-0.mx-3.color-dark No te hemos encontrado. Ingresa por favor, estos datos para darte una mejor gestión
    .form-data.mb-3.mt-4.p-4
      b-form-input(class="form-control" type="text"
        v-model.number="models.Nombres",
        placeholder="Nombre",
        required,
        autocomplete="off",
        :disabled="isAtril"
      )#name
      b-form-input(class="form-control" type="text"
        v-model.number="models.PrimerApellido",
        placeholder="Apellido",
        required,
        autocomplete="off",
        :disabled="isAtril"
      )#last_name
      b-form-input(class="form-control" 
        type="number",
        v-model.number="models.Celular",
        min="0"
        placeholder="Celular",
        required,
        autocomplete="off",
      )#phone
      b-form-input(class="form-control" type="text"
        v-model.number="models.Correo",
        placeholder="Correo",
        autocomplete="off",
      )#email
    .botton-actions-footer
      .d-flex.justify-content-between
        b-button(
          variant="bluecolmedica" 
          size="sm" 
          type="submit" 
          :disabled="!newUserReady"
          @click="continueWithExistent"
        ).w-100.py-2.text-center.mx-1
          span.font-md Continuar
</template>

<script>
import { mapState, mapActions } from "vuex";

import Header from "./components/Header";
import Continue from "mdi-vue/ArrowRightBold";
import MiniSpinner from "../components/MiniSpinner";
import Atras from "mdi-vue/ArrowLeftBold";

import IconBackDelete from "@/assets/newDesign/IconBackDelete.svg";

export default {
  name: "Home",
  components: {
    Header,
    Continue,
    MiniSpinner,
    Atras
  },

  created() {
    console.log(this.client)
    this.models = {...this.client};
  },

  data: () => ({
    myForm: "",
    captchaElement: null,
    isCapcha: true,
    models: {
      NumeroIdentificacion: "",
      TipoIdentificacion: "CC"
    },
    IconBackDelete,
  }),

  computed: {
    ...mapState({
      currentSite: state => state.virtualrow.currentSite,
      options: state => state.virtualrow.tipide,
      currentUser: state => state.virtualrow.currentUser,
      citas: state => state.virtualrow.citas,
      charging: state => state.autoservice.charging,
      isAtril: state => state.virtualrow.isAtril,
      primaryColorBlue: state => state.virtualrow.primaryColorBlue,
      secondColorBlue: state => state.virtualrow.secondColorBlue,
      threeColorBlueColor: state => state.virtualrow.threeColorBlueColor,
      error: state => state.virtualrow.error,
      client: state => state.autoservice.client
    }),

    colorSecondaryComputed() {
      return `background-color: ${this.secondColorBlue};`;
    },

    toSend() {
      return { ...this.models, CodigoOficina: this.$route.params.code };
    },

    colorComputed() {
      return `background-color: ${this.primaryColorBlue}; color: ${this.threeColorBlueColor}; border-color: ${this.primaryColorBlue};`;
    },

    newUserReady() {
      return !!(
        this.models.Nombres && 
        this.models.PrimerApellido && 
        (this.models.Celular && String(this.models.Celular).length > 7) &&
        (this.models.Correo && String(this.models.Correo).includes("@") && String(this.models.Correo).includes(".")  )
      );
    },

    siteName() {
      return this.currentSite?.name;
    },

    siteIsOffice() {
      return this.currentSite?.type === "of";
    }
  },

  methods: {
    ...mapActions({
      goToView: "autoservice/goToView",
      setClient: "autoservice/setClient",
    }),
    async continueWithExistent() {
      
      await this.setClient(this.models);
      setTimeout(() => {
        console.log(this.models)
        this.goToView("VerificationData");
      }, 1000);
      
    },
  }
};
</script>

<style scoped>
.Content {
  position: relative;
}

.CurrentUser {
  margin: auto;
  width: 100%;
  height: calc(100vh - 173px);
  overflow: auto;
  display: table;
  table-layout: fixed;
  position: relative;
}

.CurrentUser > div {
  max-height: calc(100vh - 173px);
  display: table-cell;
  -ms-text-overflow: ellipsis; /* This property is used to show ellipses, when the content within de cell is overflown. */
  -o-text-overflow: ellipsis; /* This property is used to show ellipses, when the content within de cell is overflown. */
  text-overflow: ellipsis;
  vertical-align: middle;
}

.box-dk {
  margin: auto;
  text-align: center;
  line-height: 1;
  font-size: 1.3rem;
}

.buttons {
  position: fixed;
  bottom: 0px;
  /* left: -15px;
  right: -15px; */
  left: 0;
  right: 0;
  margin: auto;
  z-index: 12300000;
  padding: 1rem;
}

form {
  margin: auto;
  margin-top: 62px;
  max-width: 491px;
}

.input,
#input {
  margin-left: auto;
  margin-right: auto;
  display: block;
  font-size: 16px;
  text-align: center;
  width: 70%;
  height: 30px;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #00599d;
}

.button {
  background-color: #0057a0;
  /* color: #fff; */
  font-size: 17px;
  margin: 30px auto;
  border-radius: 9px;
  padding: 5px;
  width: 70%;
  border: none;
  display: block;
}

.botton-actions {
  position: absolute;
  bottom: 0px;
  width: 100%;
  margin-bottom: 24px;
}

#d-flex span svg {
  width: 20px;
  height: 20px;
  /* fill: white; */
}

.big_box {
  position: relative;
}

.dk-link {
  outline: none;
  border: none;
  appearance: none;
  text-decoration: none;
  background: none;
  /* color: #6565; */
  color: #6558f5;
  display: block;
  text-align: center;
  font-size: 1.2rem;
  margin: 93px auto;
}

.dk-link:hover {
  text-decoration: underline;
}

.pos_dk {
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 30;
}

.disabled {
  cursor: not-allowed;
  background: #0058a0a4;
}

.disabled:hover {
  background: #0058a0cb;
}

.dk-pos-dk {
  position: absolute;
  top: 30%;
  left: 0px;
  font-weight: bold;
  transition: 1s;
  right: 0px;
}

.Input-text::placeholder {
  color: rgb(0 0 0) !important;
}

.form-select-container {
  position: relative;
}

.form-select-container svg {
  width: 27px;

  position: absolute;
  right: 13px;
  top: 50%;
  transform: translateY(-50%);

  background: #f4f4f4;
}

.h1 {
  font-size: 2.8rem;
}

.buttonService {
  width: 100%;
  max-width: 300.83px;
  padding: 1rem 2rem;
  background-color: #009dac;
  border-radius: 16.4277px;
  font-style: normal;
  font-weight: 400;
  font-size: 17.4744px;
  line-height: 25px;
  border: none;
  outline: none;
  text-align: center;
  color: #ffffff;
  transition: 1s background-color;
}

.buttonService:disabled {
  background-color: #788591;
}

.buttonService.outlineService {
  border: 2.05347px solid #788591;
  box-sizing: border-box;
  color: #788591;
  background: none;
}

.zeroButton {
  background-color: #ffffff;
  color: #000000;
  box-shadow: 0px 1.40556px 0px rgb(0 0 0 / 25%);
}

.disabled {
  opacity: 0.5;
}

@media screen and (max-height: 759px) {
  .dk-pos-dk {
    top: -22%;
  }
}
</style>
