<template lang="pug">
button(:class="fv").StackItem.px-4.d-flex.justify-content-between.w-full.align-items-center.text-secondary
  span
    slot
  svg(width='11' height='18' viewbox='0 0 11 18' fill='none' xmlns='http://www.w3.org/2000/svg')
    path(d='M2 2L8.99954 8.99954' stroke='#ff0304' stroke-width='2.53762' stroke-linecap='round' stroke-linejoin='round')
    path(d='M2 15.9688L8.99954 8.96921' stroke='#ff0304' stroke-width='2.53762' stroke-linecap='round' stroke-linejoin='round')
</template>

<script>
export default {
  name: "StackItem",
  props: ["fv"]
};
</script>

<style lang="scss" scoped>
.StackItem {
  border-radius: 15px;
  font-weight: 500;
  box-shadow: 0px 4px 10px rgb(0 0 0 / 10%);
  background-color: var(--color-white);
  transition: 0.5s all;
  border: none;
  padding: 1.2rem 0;
}

.StackItem span {
  font-size: var(--font-sm);
  color: var(--color-secondary);
  padding-right: 4px;
}

.StackItem svg {
  min-width: 0.8rem;
}

.StackItem.fv_span {
  padding: 1.5rem 0.8rem;
}

.StackItem svg.fv_svg {
  min-width: 1.5rem;
}
</style>
